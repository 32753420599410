export const SILDES_BY_BREAKPOINT = {
  375: {
    slidesPerView: 1.1,
  },
  725: {
    slidesPerView: 1,
  },
  956: {
    slidesPerView: 2,
  },
  1256: {
    slidesPerView: 2,
  },
  1400: {
    slidesPerView: 3,
  },
  1600: {
    slidesPerView: 3.2,
  },
  1728: {
    slidesPerView: 4,
  },
  1800: {
    slidesPerView: 4,
  },
};
