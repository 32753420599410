import { FC, ReactNode, useMemo } from 'react';

import ProgressBar from '@ramonak/react-progress-bar';
import dynamic from 'next/dynamic';
import Image from 'next/image';

import {
  Button,
  ButtonVariant,
  Typography,
  TypographyVariant,
} from '@/components';

import { useStepper } from './hooks';
import styles from './styles.module.scss';

interface IWelcomeModal {
  open: boolean;
  onClose: () => void;
}

const Modal = dynamic(
  () => import('../../../../components/modal').then((module) => module.Modal),
  {
    ssr: false,
  }
);

const WelcomeStep = () => {
  return (
    <div className={styles.welcomeWrapper}>
      <Typography variant={TypographyVariant.Heading2BoldHelvetica} as="h2">
        Welcome to Exitzero!
      </Typography>
      <Typography variant={TypographyVariant.Heading2BoldHelvetica} as="h3">
        We are delighted to have you on board!
      </Typography>
      <Typography as="p" className={styles.paragraph}>
        We&apos;ve created Exitzero to help you in the deployment and
        maintenance of various services on multiple Linux platforms.
      </Typography>
      <div className={styles.welcomeImg}>
        <Image quality={100} src="/images/buildings.png" alt="buildings" fill />
      </div>
    </div>
  );
};

const FirstStep = () => {
  return (
    <div className={styles.primaryColor}>
      <div className={styles.stepWrapper}>
        <Typography
          className={styles.step}
          variant={TypographyVariant.Heading2BoldHelvetica}
        >
          01
        </Typography>
        <Typography variant={TypographyVariant.Heading2BoldHelvetica} as="h2">
          Add host
        </Typography>
      </div>
      <div className={styles.firstContainer}>
        <Typography className={styles.secondaryColor}>
          In oroder to deploy services, firstly you will need to connect to
          Exitzero profile your Linux hosts: VM, cloud computing server,
          personal hosts, on demand server and others. To do so, tap on
          &ldquo;+&ldquo; button from the side bar, or &ldquo;Add host&ldquo;
          button from &ldquo;Hosts&ldquo; page, and you&apos;ll receive the
          activation window.
        </Typography>

        <div className={styles.imgsContainer}>
          <div className={styles.img}>
            <Image
              quality={100}
              src="/images/nav.png"
              alt="how-to-navigate"
              fill
            />
          </div>
          <div className={styles.img}>
            <Image
              quality={100}
              src="/images/btn.png"
              alt="which-button-to-press"
              fill
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const SecondStep: FC = () => {
  return (
    <div className={styles.primaryColor}>
      <div className={styles.stepWrapper}>
        <Typography
          className={styles.step}
          variant={TypographyVariant.Heading2BoldHelvetica}
        >
          02
        </Typography>
        <Typography variant={TypographyVariant.Heading2BoldHelvetica} as="h2">
          Pair your host
        </Typography>
      </div>
      <div className={styles.content}>
        <div className={styles.textsContainer}>
          <Typography className={styles.paragraph} as="p">
            In the activation window, name your host (optional) and tap
            &ldquo;Generate&ldquo; to receive a script for pairing. Run this in
            the host terminal and wait for connection.
          </Typography>
          <Typography className={styles.paragraph} as="p">
            <span className={styles.note}>Note:</span> please pay attention to
            the fact that your system must be clear OS, because our agent in
            this version will not be able to manage the services installed by
            you before.
          </Typography>
        </div>

        <div className={styles.imgsContainer}>
          <div className={styles.img}>
            <Image
              quality={100}
              src="/images/generate.png"
              alt="generate"
              fill
            />
          </div>
          <div className={styles.img}>
            <Image quality={100} src="/images/copy.png" alt="copy" fill />
          </div>
        </div>
      </div>
    </div>
  );
};

const ThirdStep: FC = () => {
  return (
    <div className={styles.primaryColor}>
      <div className={styles.stepWrapper}>
        <Typography
          className={styles.step}
          variant={TypographyVariant.Heading2BoldHelvetica}
        >
          03
        </Typography>
        <Typography variant={TypographyVariant.Heading2BoldHelvetica} as="h2">
          Choose a Service
        </Typography>
      </div>
      <div className={styles.thirdStepContainer}>
        <div className={styles.textsContainer}>
          <Typography as="p" className={styles.secondaryColor}>
            Choose a service from the Store You may now deploy services! Go to
            the &ldquo;Store&ldquo;, navigate and choose your preferred service.
          </Typography>
          <Typography as="p" className={styles.secondaryColor}>
            <span className={styles.note}>Note:</span> at the current stage, you
            may deploy only one service on your host.
          </Typography>
        </div>

        <div className={styles.thirdStepImg}>
          <Image quality={100} src="/images/services.png" alt="services" fill />
        </div>
      </div>
    </div>
  );
};

const FourthStep: FC = () => {
  return (
    <div className={styles.primaryColor}>
      <div className={styles.stepWrapper}>
        <Typography
          className={styles.step}
          variant={TypographyVariant.Heading2BoldHelvetica}
        >
          04
        </Typography>
        <Typography variant={TypographyVariant.Heading2BoldHelvetica} as="h2">
          Install Service
        </Typography>
      </div>
      <div className={styles.thirdStepContainer}>
        <Typography as="p" className={styles.secondaryColor}>
          From the service inner page choose the Host and preferred parameters
          of service, tap &ldquo;Install&ldquo; and wait for installation.
        </Typography>
        <div className={styles.fourthStepImg}>
          <Image
            quality={100}
            src="/images/install.png"
            alt="install-service"
            fill
          />
        </div>
      </div>
    </div>
  );
};

const FifthStep: FC = () => {
  return (
    <div className={styles.primaryColor}>
      <div className={styles.stepWrapper}>
        <Typography
          className={styles.step}
          variant={TypographyVariant.Heading2BoldHelvetica}
        >
          05
        </Typography>
        <Typography variant={TypographyVariant.Heading2BoldHelvetica} as="h2">
          Maintain your hosts and servies
        </Typography>
      </div>
      <div className={styles.firstContainer}>
        <Typography as="p" className={styles.secondaryColor}>
          You can further gain data and maintain your Hosts & Services
          individually from &ldquo;Hosts&ldquo; and &ldquo;My services&ldquo;
          sections.
        </Typography>

        <div className={styles.imgsContainer}>
          <div className={styles.img}>
            <Image quality={100} src="/images/hosts.png" alt="hosts" fill />
          </div>
          <div className={styles.img}>
            <Image
              quality={100}
              src="/images/singleHost.png"
              alt="single-host"
              fill
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const WelcomeModal: FC<IWelcomeModal> = ({ onClose, open }) => {
  const { currentStep, handleNext, handleSkip } = useStepper(onClose);

  const stepComponents = useMemo<Record<number, ReactNode>>(
    () => ({
      0: <WelcomeStep />,
      1: <FirstStep />,
      2: <SecondStep />,
      3: <ThirdStep />,
      4: <FourthStep />,
      5: <FifthStep />,
    }),
    []
  );

  return (
    <Modal isOpen={open} onClose={onClose} fullWidth isOverlayClickDisabled>
      <div className={styles.welcomeModal}>
        <div className={styles.container}>{stepComponents[currentStep]}</div>
        <div className={styles.actions}>
          {/* <ProgressBar currentStep={currentStep} /> */}
          {!currentStep ? (
            <Typography className={styles.secondaryColor}>
              Let us introduce you to the core functionality of{' '}
              <Typography className={styles.primaryColor}>Exitzero</Typography>
            </Typography>
          ) : (
            <div className={styles.progressBar}>
              <Typography>{currentStep}&nbsp;/&nbsp;5</Typography>
              <ProgressBar
                completed={currentStep * 20}
                isLabelVisible={false}
                width="300px"
                height="4px"
                bgColor="#3478F6"
                baseBgColor="rgba(255,255,255,0.1)"
              />
            </div>
          )}
          <div className={styles.buttons}>
            {currentStep > 0 && currentStep !== 5 && (
              <Button
                variant={ButtonVariant.Link}
                className={styles.skip}
                onClick={handleSkip}
                {...{ 'aria-label': 'skip-for-now' }}
              >
                Skip for Now
              </Button>
            )}
            <Button {...{ 'aria-label': 'Start' }} onClick={handleNext}>
              {currentStep === 0
                ? 'Start'
                : currentStep === 5
                ? 'Done'
                : 'Next'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
