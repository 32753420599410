import { useCallback, useState } from 'react';

export const useStepper = (closeModal: () => void) => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = useCallback(() => {
    if (currentStep === 5) {
      closeModal();
      setCurrentStep(0);
    } else {
      setCurrentStep((prev) => (prev += 1));
    }
  }, [currentStep, closeModal]);

  const handleSkip = useCallback(() => {
    closeModal();
    setCurrentStep(0);
  }, [closeModal]);

  return { currentStep, handleNext, handleSkip };
};
