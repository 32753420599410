import Image from 'next/image';

import { Typography, TypographyVariant } from '@/components';

import styles from './styles.module.scss';

export const Hero = () => {
  return (
    <section className={styles.heroWrapper}>
      <article className={styles.articleWrapper}>
        <Typography as="h1" className={styles.titleWrapper}>
          <Typography
            className={styles.blueTitle}
            variant={TypographyVariant.Heading2Bold}
          >
            Automated
          </Typography>
          <Typography variant={TypographyVariant.Heading2Regular}>
            Deployment & Maintenance
          </Typography>
          <div className={styles.groupText}>
            <Typography variant={TypographyVariant.Heading2Regular}>
              on multiple
            </Typography>
            <Typography
              className={styles.blueTitle}
              variant={TypographyVariant.Heading2Bold}
            >
              Linux hosts
            </Typography>
          </div>
        </Typography>
        <Typography as="p" className={styles.description}>
          Exitzero lets you install services in few simple clicks on any
          paired&nbsp;
          <br />
          VM, cloud computing server, personal host or on-demand server.
        </Typography>
      </article>
      <div className={styles.imageWrapper}>
        <Image
          src="/images/hero_bg.svg"
          alt="Exitzero"
          quality={100}
          priority
          className={styles.image}
          fill
        />
      </div>
    </section>
  );
};
