import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { Tooltip } from 'react-tooltip';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as LeftIcon } from '@/assets/icons/arrow-left.svg';
import { ReactComponent as RightIcon } from '@/assets/icons/arrow-right.svg';
import {
  Button,
  ButtonVariant,
  Card,
  CardVariant,
  Typography,
} from '@/components';

import { SILDES_BY_BREAKPOINT } from './constants';
import styles from './styles.module.scss';
import { IEditorChoice } from './types';

SwiperCore.use([Navigation]);

export const EditorChoice = () => {
  const [tooltips, setTooltips] = useState<Record<string, boolean>>({});
  const router = useRouter();
  const { data } = useQuery<IEditorChoice[]>({
    enabled: false,
    queryKey: ['editor-choice'],
  });

  const handleRedirectInstallPage = (name: string, id: string) =>
    router.push(`/store/${name.toLowerCase()}-${id}`);

  if (!data || !data?.length) {
    return null;
  }

  return (
    <section className={styles.editorChoiceWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.buttonWrapper}>
          <Button
            id="editor-choice-prev"
            variant={ButtonVariant.Secondary}
            className={styles.button}
            {...{ 'aria-label': 'left-icon' }}
          >
            <LeftIcon />
          </Button>
        </div>
        <Swiper
          modules={[Navigation]}
          breakpoints={SILDES_BY_BREAKPOINT}
          slidesPerView="auto"
          loop
          spaceBetween={20}
          navigation={{
            nextEl: '#editor-choice-next',
            prevEl: '#editor-choice-prev',
          }}
          scrollbar={{ draggable: true }}
        >
          {[...data, ...data]?.map(
            (
              {
                productId,
                logoUrl,
                serviceCategory,
                serviceName,
                additionaDate,
                metaDescription,
              },
              index
            ) => (
              <SwiperSlide key={`${productId}-${index}`}>
                <Card
                  id={`${productId}-${index}`}
                  isEditorChoice
                  className={styles.card}
                  variant={CardVariant.Horizontal}
                  title={serviceName}
                  uploadDate={additionaDate}
                  categoryTitle={serviceCategory}
                  metaDescription={metaDescription}
                  iconUrl={logoUrl}
                  onInstall={() =>
                    handleRedirectInstallPage(serviceName, productId)
                  }
                  onSetTooltip={(isTooltipExist) =>
                    setTooltips((prev) => {
                      return {
                        ...prev,
                        [`${productId}-${index}`]: isTooltipExist,
                      };
                    })
                  }
                />
              </SwiperSlide>
            )
          )}
        </Swiper>

        {[...data, ...data]?.map(({ productId, metaDescription }, index) => {
          return (
            <Tooltip
              key={`${productId}-${index}`}
              id={`card-meta-${`${productId}-${index}`}`}
              className={styles.tooltip}
              place="bottom-start"
              hidden={!tooltips[`${productId}-${index}`]}
            >
              <Typography className={styles.tooltipWidth} as="p">
                {metaDescription}
              </Typography>
            </Tooltip>
          );
        })}
        <div className={styles.buttonWrapper}>
          <Button
            id="editor-choice-next"
            variant={ButtonVariant.Secondary}
            className={styles.button}
            {...{ 'aria-label': 'right-icon' }}
          >
            <RightIcon />
          </Button>
        </div>
      </div>
    </section>
  );
};
