/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sort-keys-fix/sort-keys-fix */
//@ts-nocheck
import { useInfiniteQuery } from '@tanstack/react-query';

import api from '@/services/api';

import { IServicesParam } from '../types';

const fetchServices = async ({
  pageParam,
  queryKey,
}: {
  pageParam: IServicesParam;
  queryKey: number[] | string[] | boolean[];
}) => {
  const {
    isDocker = queryKey[4],
    pageNumber = 1,
    pageSize = 16,
    searchTerm = queryKey[3],
  } = pageParam || {};
  const response = await api.get('/sr-api/Product/all', {
    params: {
      isDocker,
      pageNumber,
      pageSize,
      searchTerm,
    },
  });

  return response.data;
};

export const useServices = <T>(
  pageNumber: number,
  pageSize: number,
  searchTerm: string,
  isDocker: boolean | null
) => {
  return useInfiniteQuery<T>({
    queryFn: fetchServices,
    queryKey: ['store-services', pageNumber, pageSize, searchTerm, isDocker],
    getNextPageParam: (lastPage: IServicesParam) => {
      if (lastPage.pageNumber >= lastPage.totalPages) {
        return false;
      }

      return { ...lastPage, pageNumber: lastPage.pageNumber + 1 };
    },
  });
};
