import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import {
  Card,
  CardVariant,
  ToggleButton,
  Typography,
  TypographyVariant,
} from '@/components';
import { useDockerActiveId, useDockerChecklist, useIsDocker } from '@/hooks';

import styles from './styles.module.scss';
import { IHomeServices } from './types';

const fetchServices = async (
  limit: number,
  isDocker: boolean | null = true
) => {
  const resp = await fetch(
    `/sr-api/Product/all?pageNumber=1&pageSize=${limit}&isDocker=${isDocker}`
  );

  return await resp.json();
};
export const Services = () => {
  const { isDocker, setIsDocker } = useIsDocker();
  const { setCheckedItems } = useDockerChecklist();
  const { setActiveId } = useDockerActiveId();
  const router = useRouter();
  const { data: services } = useQuery<IHomeServices>({
    enabled: typeof isDocker === 'boolean',
    queryFn: () => fetchServices(6, isDocker),
    queryKey: ['home-services', isDocker],
  });

  const handleRedirectInstallPage = (name: string, id: string) =>
    router.push(`/store/${name.toLowerCase()}-${id}`);

  const handleActiveState = (id: number) => {
    sessionStorage.removeItem('activeId');
    sessionStorage.removeItem('savedData');
    sessionStorage.removeItem('dockerCheckedItems');
    setCheckedItems({});
    setActiveId(undefined);
    setIsDocker(id === 2 ? false : true);
    sessionStorage.setItem('isDocker', JSON.stringify(id === 2 ? false : true));
  };

  return (
    <section className={styles.servicesWrapper}>
      <div className={styles.storeTitleWrapper}>
        <Typography
          as="h2"
          variant={TypographyVariant.Heading1Bold}
          className={styles.title}
        >
          Services
        </Typography>
        <ToggleButton
          options={[
            { id: 1, label: 'Docker' },
            { id: 2, label: 'On premise ' },
          ]}
          defaultActive={isDocker === null ? 0 : isDocker ? 1 : 2}
          onActiveState={handleActiveState}
        />
      </div>
      <div className={styles.services}>
        {services?.data?.map(
          ({
            productId,
            logoUrl,
            serviceCategory,
            serviceName,
            additionaDate,
            metaDescription,
          }) => {
            return (
              <Card
                id={productId}
                key={productId}
                variant={CardVariant.Horizontal}
                title={serviceName}
                uploadDate={additionaDate}
                categoryTitle={serviceCategory}
                metaDescription={metaDescription}
                iconUrl={logoUrl}
                isDocker={isDocker}
                onInstall={() =>
                  handleRedirectInstallPage(serviceName, productId)
                }
              />
            );
          }
        )}
      </div>
    </section>
  );
};
