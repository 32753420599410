/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
//@ts-nocheck
import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

import { ReactComponent as DiscordIcon } from '@/assets/icons/discord.svg';
import { ReactComponent as GitLabIcon } from '@/assets/icons/gitlab2.svg';
import { ReactComponent as LinkedinIcon } from '@/assets/icons/linkedin.svg';
import { ReactComponent as YouTubeIcon } from '@/assets/icons/youtube.svg';
import {
  Button,
  ButtonVariant,
  Divider,
  Typography,
  TypographyVariant,
} from '@/components';
import { OnboardingStep } from '@/features';
import {
  useDockerActiveId,
  useDockerChecklist,
  useIsDocker,
  useIsMobile,
  useMediaQuery,
} from '@/hooks';
import { emitEvent, EMixPanelEventsMap } from '@/services/mixpanel';

import { EditorChoice, Services, Tutorial, WelcomeModal } from './features';
import styles from './styles.module.scss';
import { ICategories } from '../store/features/categories/types';
import { useServices } from '../store/features/services/hooks';
import { IStorePages } from '../store/features/services/types';

export default function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 1440px)');
  const { status } = useSession();
  const isAuthenticated = status === 'authenticated';
  const isMobile = useIsMobile();
  const { setCheckedItems } = useDockerChecklist();
  const { setActiveId } = useDockerActiveId();
  const { setIsDocker } = useIsDocker();
  const router = useRouter();
  const { data: categories } = useQuery<ICategories[]>({
    enabled: false,
    queryKey: ['store-categories'],
  });
  const AiCategoryName = categories?.find(
    (category) => category.id === 'a48d0fb2-ffab-4acc-88c6-1776abb0375e'
  )?.name;

  const { data: services } = useServices<IStorePages>(
    1,
    16,
    AiCategoryName,
    true
  );

  useEffect(() => {
    const { query } = router;

    if (query.isNewUser) {
      emitEvent(EMixPanelEventsMap.REGISTER_EVENT);
      setTimeout(() => setIsModalOpen(true), 1000);
      window.history.replaceState(null, '', '/');
    }
  }, [router, setIsModalOpen]);

  const handleOnpremiseAll = () => {
    sessionStorage.removeItem('activeId');
    sessionStorage.removeItem('savedData');
    sessionStorage.removeItem('dockerCheckedItems');
    setCheckedItems({});
    setActiveId(undefined);
    setIsDocker(false);
    sessionStorage.setItem('isDocker', JSON.stringify(false));
    router.push('/store');
  };

  const handleRedirect = (url: string) => {
    window.open(url);
  };

  const data = isDesktop
    ? services?.pages[0].data
    : services?.pages[0].data?.slice(0, 3);

  const handleDockerAll = () => {
    setIsDocker(true);
    sessionStorage.setItem('isDocker', JSON.stringify(true));
    router.push('/store');
  };

  const handleAIAll = () => {
    setIsDocker(true);
    sessionStorage.setItem('isDocker', JSON.stringify(true));
    router.push(`/store?filterText=${AiCategoryName}`);
  };

  const handleAIInstal = (name: string, id: string) => {
    setIsDocker(true);
    sessionStorage.setItem('isDocker', JSON.stringify(true));
    router.push(`/store/${name.trim()}-${id}`);
  };

  const handleSignUp = () => {
    window.open(
      `${
        process.env.NEXT_PUBLIC_AUTHORITY as string
      }/Account/Register?returnUrl=${
        process.env.NEXT_PUBLIC_HOST_URI as string
      }`,
      '_self'
    );
  };

  return (
    <section className={styles.homeWrapper}>
      <section className={styles.hero}>
        <div className={styles.bg}>
          <Image
            priority
            quality="100"
            src={
              isMobile
                ? '/images/section-1-mob-bg.png?v=1'
                : '/images/section-1-bg.png?v=1'
            }
            alt="exitzero"
            fill
            className={styles.image}
          />
        </div>
        <div className={styles.textWrapper}>
          <Typography as="h1" variant={TypographyVariant.Heading2BoldHelvetica}>
            Install & maintain <br />
            services automated <br />
            on Linux machines
          </Typography>
          <Typography
            as="p"
            className={styles.desc}
            variant={TypographyVariant.Body2Regular}
          >
            Exitzero lets you install services through automated deployment flow
            and pre-configured scripts on any VM, cloud computing server,
            personal host or on-demand server.
          </Typography>
          <div className={styles.buttonGroup}>
            {!isAuthenticated && (
              <Button onClick={handleSignUp}>Get for free</Button>
            )}
            <Tutorial />
          </div>
        </div>
        <EditorChoice />
        <OnboardingStep className={styles.onboarding} />
        <section className={styles.bannerWrapper}>
          <div className={styles.banner}>
            <div className={styles.bg}>
              <Image
                priority
                quality="100"
                src={
                  isMobile
                    ? '/images/banner-1-mob.png?v=1'
                    : '/images/banner-1.png?v=1'
                }
                alt="exitzero"
                fill
                className={styles.bannerImg}
              />
            </div>
            <div className={styles.bannerTextWrapper}>
              <Typography
                as="h2"
                variant={TypographyVariant.Heading2BoldHelvetica}
              >
                On-premise <br />
                Services
              </Typography>
              <Typography
                as="p"
                className={styles.desc}
                variant={TypographyVariant.Body1Regular}
              >
                Explore a wide range of tools, tailored for on-premise
                installation to empower you with full control and privacy over
                your infrastructure.
              </Typography>
              <div className={styles.buttonGroup}>
                <Button onClick={handleOnpremiseAll}>See all</Button>
              </div>
            </div>
          </div>
          <div className={clsx(styles.banner, styles.banner2)}>
            <div className={styles.bg}>
              <Image
                priority
                quality="100"
                src={
                  isMobile
                    ? '/images/banner-2-mob.png?v=1'
                    : '/images/banner-2.png?v=1'
                }
                alt="exitzero"
                fill
                className={styles.bannerImg}
              />
            </div>
            <div className={styles.bannerTextWrapper}>
              <Typography
                as="h2"
                variant={TypographyVariant.Heading2BoldHelvetica}
              >
                Dockerized <br />
                Services
              </Typography>
              <Typography
                as="p"
                className={styles.desc}
                variant={TypographyVariant.Body1Regular}
              >
                An extensive selection of services is available for seamless
                installation within Docker containers, ensuring flexibility and
                convenience.
              </Typography>
              <div className={styles.buttonGroup}>
                <Button onClick={handleDockerAll}>See all</Button>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.bannerWrapper}>
          <div className={styles.banner3}>
            <div className={styles.bg}>
              <Image
                priority
                quality="100"
                src="/images/banner-3.png?v=2"
                alt="exitzero"
                fill
                className={styles.bannerImg}
              />
            </div>
            <div
              className={clsx(
                styles.bannerTextWrapper,
                styles.banner3TextWrapper
              )}
            >
              <Typography
                as="h2"
                variant={TypographyVariant.Heading2BoldHelvetica}
              >
                AI Services
              </Typography>
              <Typography
                as="p"
                className={styles.desc}
                variant={TypographyVariant.Body1Regular}
              >
                Deploy the top-used services for creating and maintaining
                scalable AI infrastructures in a few simple clicks with
                Exitzero.
              </Typography>
              <div className={styles.buttonGroup}>
                <Button onClick={handleAIAll}>See all</Button>
              </div>
            </div>
            <div className={styles.cardAiWrapper}>
              {data?.map((item) => (
                <div key={item.productId} className={styles.cardAi}>
                  <div className={styles.imgGroup}>
                    <div className={styles.imgWrapper}>
                      <Image
                        priority
                        quality={100}
                        width={52}
                        height={52}
                        alt={item.serviceName}
                        src={item.logoUrl}
                      />
                    </div>
                    <div className={styles.textGroup}>
                      <div className={styles.bageGroup}>
                        <Typography
                          variant={TypographyVariant.Body2Medium}
                          as="h2"
                        >
                          {item.serviceName}
                        </Typography>
                        <Typography className={styles.bage}>AI</Typography>
                      </div>
                      <Typography as="p" className={styles.desc}>
                        {item.serviceCategory}
                      </Typography>
                    </div>
                  </div>
                  <Button
                    onClick={() =>
                      handleAIInstal(item.serviceName, item.productId)
                    }
                  >
                    Install
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </section>
        <Services />
        <section className={styles.bannerWrapper}>
          <div className={styles.banner4}>
            <div className={styles.bg}>
              <Image
                priority
                quality="100"
                src="/images/banner-4.png?v=2"
                alt="exitzero"
                fill
                className={styles.bannerImg}
              />
            </div>
            <div className={styles.banner4TextWrapper}>
              <Typography
                as="h2"
                variant={TypographyVariant.Body1Medium}
                className={styles.title}
              >
                Automate installations securely
                <br /> with the power of Exitzero
              </Typography>
              <Typography as="p" className={styles.desc}>
                We ensure your secure connection with our end-to-end encrypted
                architecture.
                <br /> Discover how Exitzero works in the{' '}
                <Link href="/about-us">About Us</Link> section.
              </Typography>
            </div>
          </div>
        </section>
        <Divider />
        <footer className={styles.footer}>
          <div className={styles.iconWrapper}>
            <Button
              className={styles.button}
              variant={ButtonVariant.Secondary}
              onClick={() =>
                handleRedirect('https://www.linkedin.com/company/exitzero')
              }
              {...{ 'aria-label': 'linkedin-button' }}
            >
              <LinkedinIcon />
            </Button>
            <Button
              className={styles.button}
              variant={ButtonVariant.Secondary}
              onClick={() =>
                handleRedirect(
                  'https://gitlab.com/exit-zero/installation-scripts'
                )
              }
              {...{ 'aria-label': 'gitlab-button' }}
            >
              <GitLabIcon />
            </Button>
            <Button
              onClick={() =>
                handleRedirect('https://www.youtube.com/watch?v=eB5ImmWELXg')
              }
              className={styles.button}
              variant={ButtonVariant.Secondary}
              {...{ 'aria-label': 'youtube-button' }}
            >
              <YouTubeIcon />
            </Button>
            <Button
              className={styles.button}
              variant={ButtonVariant.Secondary}
              onClick={() => handleRedirect('https://discord.gg/XQN3mH5mVP')}
              {...{ 'aria-label': 'discord-button' }}
            >
              <DiscordIcon />
            </Button>
          </div>
          <div className={styles.btnGroup}>
            {/* <Button
              variant={ButtonVariant.Link}
              onClick={() => router.push('/blog')}
            >
              <Typography>Blog</Typography>
            </Button> */}
            {/* <div className={styles.divider} /> */}
            <Button
              variant={ButtonVariant.Link}
              onClick={() => router.push('/terms')}
            >
              <Typography>Terms & Conditions and Privacy Policy</Typography>
            </Button>
            <div className={styles.divider} />
            <Button
              variant={ButtonVariant.Link}
              onClick={() => router.push('/about-us')}
            >
              <Typography>About us</Typography>
            </Button>
          </div>
          <Typography
            className={styles.copyRightsColor}
            variant={TypographyVariant.Body1Small}
          >
            ©{new Date().getFullYear()} Exitzero
          </Typography>
        </footer>
      </section>
      <WelcomeModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </section>
  );
}
