import { GetServerSidePropsContext } from 'next';

import { dehydrate, QueryClient } from '@tanstack/react-query';

import { getIsSsrMobile } from '@/services/get-is-ssr-mobile';

import Home from './home2';

export default function HomePage() {
  return <Home />;
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const queryClient = new QueryClient();
  const fetchEditorChoice = async () => {
    const resp = await fetch(
      `${process.env.NEXT_PUBLIC_SR_API_URI as string}/Product/editor/choice`
    );

    return await resp.json();
  };

  const fetchServices = async (limit: number, isDocker: boolean) => {
    const resp = await fetch(
      `${
        process.env.NEXT_PUBLIC_SR_API_URI as string
      }/Product/all?pageNumber=1&pageSize=${limit}&isDocker=${isDocker}`
    );

    return await resp.json();
  };

  const fetchCategories = async () => {
    const resp = await fetch(
      `${process.env.NEXT_PUBLIC_SR_API_URI as string}/CategoryService/all`
    );

    return await resp.json();
  };

  await queryClient.prefetchQuery({
    queryFn: () => fetchEditorChoice(),
    queryKey: ['editor-choice'],
  });

  await queryClient.prefetchQuery({
    queryFn: () => fetchServices(6, false),
    queryKey: ['home-services', false],
  });

  await queryClient.prefetchQuery({
    queryFn: () => fetchCategories(),
    queryKey: ['store-categories'],
  });

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      isMobile: getIsSsrMobile(context),
    },
  };
}
